import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

const storyTemplate = ( { data: { strapi: { story } }, location } ) => {

	return (
		<>
			<SEO title={ `${ story.title } | Story` } description={ story.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<Img className="article__image article__image--story" fluid={{ ...story.image.imageFile.childImageSharp.fluid, aspectRatio: 1 }} />

					<Link to="/stories/" className="article__back-link">Back to stories</Link>

					<h1 className="article__header">{ story.pageTitle }</h1>
					<h3 className="article__sub-header">by { story.writtenBy }</h3>

					<div className="article__body-content">
						{ story && story.content &&
							<DynamicZone components={ story.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default storyTemplate

export const query = graphql`
	query storyQuery($id: ID!) {
		strapi {
			story(id: $id) {
				id
				seoDescription
				title
				pageTitle
				writtenBy
				image {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						title
						wideImage
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
					... on StrapiQuery_ComponentCommonSectionLink {
						id
						linkTitle
						linkUrl
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoTitle
						videoNumber
					}
				}
			}
		}
	}
`